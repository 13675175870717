import React from "react";
import { Link as MetaLink, Title } from "react-head";
import { Link, Switch, Redirect } from "react-router-dom";

import Route from "./route";

import "./index.css";
import Mousey from "./media/mousey.svg";
import GitHub from "./media/github.svg";
import Star from "./media/star.svg";
import UserPlus from "./media/user-plus.svg";

const INVITE =
  "https://discord.com/oauth2/authorize?client_id=288369203046645761&permissions=961932534&scope=applications.commands+bot";

function Home() {
  return (
    <>
      <Title>Home - Mousey</Title>
      <img src={Mousey} alt="Mousey" height="128" width="128" />
      <div className="links">
        <a href={INVITE} title="Add Mousey">
          <img src={UserPlus} alt="" />
        </a>
        <a href="https://github.com/LostLuma/Mousey" title="GitHub">
          <img src={GitHub} alt="" />
        </a>
        <a href="https://lostluma.dev" title="LostLuma">
          <img src={Star} alt="" />
        </a>
      </div>
    </>
  );
}

function NotFound() {
  return (
    <>
      <Title>Not Found - Mousey</Title>
      <div>
        Looks like you took a wrong turn! <Link to="/">Go back.</Link>
      </div>
    </>
  );
}

export default function App() {
  return (
    <div className="app">
      <MetaLink rel="icon" href={Mousey} />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="*" status={404}>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}
