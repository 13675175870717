import React from "react";
import { Route } from "react-router-dom";

export default function StatusRoute(props) {
  const { children, status } = props;

  function render({ staticContext }) {
    if (staticContext) {
      staticContext.status = status;
    }

    return children;
  }

  return <Route render={render} />;
}
