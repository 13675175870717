import React from "react";
import ReactDOM from "react-dom";
import { HeadProvider } from "react-head";
import { BrowserRouter } from "react-router-dom";

import App from ".";

const app = (
  <BrowserRouter>
    <HeadProvider>
      <App />
    </HeadProvider>
  </BrowserRouter>
);

const mount = document.getElementById("root");

if (process.env.NODE_ENV === "development") {
  ReactDOM.render(app, mount);
} else {
  ReactDOM.hydrate(app, mount);
}
